<template>
  <div>

    <div
      class=" conOfCampaignHead d-flex align-items-center "
    >
      <b-col md="2">
        Image
      </b-col>
      <b-col md="2">
        Product
      </b-col>
      <b-col md="2">
        Price
      </b-col>
      <b-col md="2">
        Gift
      </b-col>
      <b-col md="2">
        Campaign Status
      </b-col>
      <b-col md="2">
        Campaign Type
      </b-col>
    </div>
    <draggable
      v-model="campaigns"

      group="people"
      @start="drag=true"
      @change="moved"
      @end="drag=false"
    >
      <div
        v-for="element in campaigns"
        :key="element.id"
        class=" conOfCampaign d-flex align-items-center "
      >

        <b-col md="2">
          <img
            v-img
            :src="element.image"
            :alt="element.product_name"
            width="70"
          >        </b-col>
        <b-col md="2">
          {{ element.product_name }}
        </b-col>
        <b-col md="2">
          {{ element.product_price }}
        </b-col>
        <b-col
          v-if="element.gift_names.length!==0"
          md="2"
        >
          <ul
            v-for="(gift,index) in element.gift_names "
            :key="index"
            class="giftName"
          >
            <li>
              {{ gift.name }}

            </li>
          </ul>
        </b-col>
        <b-col
          v-if="element.gift_name"
          md="2"
        >
          {{ element.gift_name }}
        </b-col>
        <b-col md="2">
          {{ element.status }}
        </b-col>
        <b-col md="2">
          {{ getTypes(element.campaign_id) }}
        </b-col>
      </div>
    </draggable>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  components: { draggable },
  data() {
    return {
      campaigns: [],
      types: [
        {
          name: 'Active Campaigns',
          id: 1,
        }],
      filter: {
        type: 'active',
      },
      allTypes: '',
      APIURL: 'admin/product_gifts',
      addType: 'Add New Campaign',
      type: 'page',
      urlType: 'campaigns',
      addComponentName: 'add-campaign',
      editComponent: 'edit-campaign',
      viwComponent: 'show-campaign',
      columns: [
        { key: 'image', label: 'Image' },
        { key: 'product_name', label: 'Product name' },
        { key: 'price', label: 'Price' },
        { key: 'gift_name', label: 'Gift name' },
        { key: 'country_name', label: 'Country' },
        { key: 'number_for_free_tickets', label: 'No. Of Free Tickets' },
        { key: 'ticket_count', label: 'Tickets Count' },
        { key: 'quantity_limit', label: 'Quantity limit' },
        { key: 'actions' },
      ],
    }
  },
  created() {
    this.getCampaigns()
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.types.filter(item => item.text !== type)
      this.allTypes = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Normal'
      } if (type === 2) {
        return 'Flash Sale'
      }
      if (type === 3) {
        return 'Offline Buy'
      }
      return 'Normal'
    },
    moved(item) {
      const index = item.moved.newIndex + 1
      axios.post(`admin/change_campaign_order/${item.moved.element.id}?order=${index}`).then(res => {
        if (res.status === 200) {
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            type: 'sucess',
            duration: 3000,
          })
        } else {
          this.$toasted.show('Something went wrong', {
            position: 'top-center',
            type: 'error',
            duration: 3000,
          })
        }
      })
    },
    async getCampaigns() {
      await axios.get('admin/product_gifts', { params: { type: this.filter.type } }).then(res => {
        // await axios.get('admin/product_gifts').then(res => {
        if (res.status === 200) {
          this.campaigns = res.data?.data.products
        }
      })
    },
  },
}
</script>

<style scoped>
.conOfCampaign{
  cursor:all-scroll;
  background: #fff;
    padding: 8px;
    border-bottom: 1px solid #e4dede;}
    .conOfCampaign:hover{
      background: #e8e8e8;

    }
    .conOfCampaignHead{
  background: #fff;
    padding: 8px;
    border-bottom: 1px solid #e4dede;
  }
  .giftName{
    padding-left: 1rem;
  }

</style>
